<template>
  <NavQuestion
    :heading="heading"
    :number="'2'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch
        :checked="details.guardians_secondary_required"
        @toggle="save"
      ></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/guardians/primary'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'

import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'GuardiansSecondaryOption',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    details() {
      return this.$store.getters.details
    },
    isValidated() {
      return true
    },
    heading() {
      return 'Would you like to name secondary guardians?'
    },
    subHeading() {
      return (
        'You can name people if ' +
        this.guardiansPrimaryText +
        ' unable or willing to act.'
      )
    },
    guardiansPrimaryText() {
      var string = ''
      var peopleList = this.people.filter((person) => person.guardian_primary)
      if (peopleList.length > 1)
        string += this.listPeople(peopleList, ' and ', true) + ' are'
      else string += this.listPeople(peopleList) + ' is'
      return string
    },
    forwardTo() {
      if (this.details.guardians_secondary_required)
        return '/guardians/secondary'
      return '/guardians/check_and_confirm'
    },
    people() {
      return this.$store.getters.people
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    save() {
      if (this.details) {
        this.$store.commit('details', {
          guardians_secondary_required: !this.details
            .guardians_secondary_required
        })
        if (this.details.guardians_secondary_required === false) {
          this.resetGuardiansSecondary()
        }
      }
    },
    resetGuardiansSecondary() {
      this.people.forEach((person) => {
        person.guardian_secondary = false
        this.$store.commit('personAdd', person)
      })
    }
  }
}
</script>

<style></style>
